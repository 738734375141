<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CASH VOUCHER</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12>
          <v-form ref="form" class="multi-col-validation">
            <v-layout row wrap class="mx-1 mb-5" v-if="!has_existing_voucher">
              <v-flex xs12 md6>
                <v-text-field
                  v-model="bank_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Bank"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="cheque_date"
                  class="mx-2"
                  dense
                  outlined
                  label="Date"
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="payee"
                  class="mx-2"
                  dense
                  outlined
                  label="Payee"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="allocation_of_fund"
                  class="mx-2"
                  dense
                  outlined
                  label="Name of Project"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-select
                  dense
                  outlined
                  v-model="type_of_sector"
                  :items="['Health','Education','Community','Livelihood','Youth','Environment','Protection','Meetings']"
                  label="Type of Sector"
                  required
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
              <v-flex xs12 md12>
                <v-combobox
                  :append-icon="icons.mdiAccountSearchOutline"
                  v-model="name_of_sponsor"
                  @keyup.enter="searching_search_items($event.target.value)"
                  :items="search_items"
                  item-value="id"
                  item-text="name"
                  placeholder="Name of Sponsor"
                  ref="memberCombobox"
                  dense
                  outlined
                ></v-combobox>
              </v-flex>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  class="w-full"
                  color="primary"
                  @click="save_voucher"
                  v-if="!saving_data"
                >
                  Create Voucher
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-layout>
            <v-layout row wrap class="mx-1 mb-5" v-else>
              <v-flex xs12 md12>
                <v-text-field
                  outlined
                  v-model="existing_voucher.voucher_no"
                  class="mx-2"
                  dense
                  label="Voucher #"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="existing_voucher.bank"
                  class="mx-2"
                  dense
                  outlined
                  label="Bank"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6 v-if="!is_auto_debit">
                <v-text-field
                  v-model="existing_voucher.date"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque Date"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="existing_voucher.payee"
                  class="mx-2"
                  dense
                  outlined
                  label="Payee"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="existing_voucher.allocation_of_fund"
                  class="mx-2"
                  dense
                  outlined
                  label="Name of Project"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="existing_voucher.type_of_sector"
                  class="mx-2"
                  dense
                  outlined
                  label="Type of Sector"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="existing_voucher.sponsor_name"
                  class="mx-2"
                  dense
                  outlined
                  label="Name of Sponsor"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12" v-if="existing_voucher.request_data.length===0">
                <v-btn
                  class="w-full"
                  color="error"
                  @click="delete_voucher"
                  v-if="!saving_data"
                >
                  Delete Voucher
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="error"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
              <v-col cols="12" md="12" v-else>
                <v-btn
                  class="w-full"
                  color="info"
                  @click="approve_voucher"
                  v-if="!saving_data"
                >
                  Approve Voucher
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="info"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-layout>
          </v-form>
        </v-flex>
        <v-layout col>
          <v-flex xs12 md3>
            <v-form ref="form2" class="multi-col-validation" v-if="has_existing_voucher">
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">Request Data</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-select
                v-model="request_id"
                class="mx-2 mt-4"
                dense
                outlined
                label="Request ID"
                :items="request_items"
                item-value="id"
                item-text="id"
                @change="selected_request"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-col cols="12" v-show="alert_request">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"
                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message_request}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  class="w-full"
                  color="primary"
                  @click="save_request"
                  v-if="!saving_data_request"
                >
                  Save Request
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-form>
            <v-data-table dense
                          :headers="headers2"
                          :items="vouchered_items"
                          disable-pagination
                          hide-default-footer
                          height="530"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.voucher_no }}
                  </td>
                  <td>
                    {{ item.bank }}
                  </td>
                  <td>
                    {{ item.date }}
                  </td>
                  <td>
                    {{ item.payee }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex xs12 md9 class="mx-2" v-if="data_items.length>0">
            <h2>Request Data | Total: P {{total_amount}}</h2>
            <v-data-table dense
                          :headers="headers"
                          :items="data_items"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.request_id }}
                  </td>
                  <td>
                    {{ item.date }}
                  </td>
                  <td>
                    {{ item.created_by }}
                  </td>
                  <td>
                    {{ item.sector }}
                  </td>
                  <td>
                    {{ item.particulars }}
                  </td>
                  <td>
                    {{ item.description }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',
      type_of_sector: '',
      saving_data_request: false,
      alert_request: false,
      alert_message_request: '',
      total_amount: 0,
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Request ID', value: 'tin', sortable: false},
        {text: 'Date', value: 'tin', sortable: false},
        {text: 'Created By', value: 'tin', sortable: false},
        {text: 'Sector', value: 'tin', sortable: false},
        {text: 'Particulars', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Amount', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'Voucher#', value: 'date_of_deposit', sortable: false},
        {text: 'Bank', value: 'last_name', sortable: false},
        {text: 'Date', value: 'last_name', sortable: false},
        {text: 'Payee', value: 'tin', sortable: false},
      ],
      data_items_original: [],
      data_items: [],
      request_id: '',
      request_items: [],
      category_id: '',
      category_items: [],
      allocation_of_fund: '',
      payee: '',
      is_auto_debit: false,
      cheque_no: '',
      cheque_date: '',
      remaining_balance: '',
      voucher_no: '',
      bank_id: '',
      bank_items: [],
      cheque_items: [],
      vouchered_items: [],

      has_existing_voucher: false,
      existing_voucher: {},
      bal_loading: false,

      name_of_sponsor: '',
      search_items: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['user_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('cash_vouchers', ['initialize_cash_voucher', 'save_request_data_cash_voucher', 'approved_cash_voucher', 'create_cash_voucher', 'delete_cash_voucher']),
      ...mapActions('request', ['list_of_available_for_cash_voucher']),
      ...mapActions('members_information', ['search_all_members']),
      searching_search_items(value) {
        this.search_all_members({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      initialize_data() {
        this.category_id = ''
        this.request_id = ''
        this.request_items = []
        this.initialize_cash_voucher({
          created_id: this.user_id,
        })
          .then(response => {
            this.vouchered_items = response.data[0].approved_vouchers
            this.existing_voucher = response.data[0].existing
            this.has_existing_voucher = this.existing_voucher != null
            if (this.has_existing_voucher) {
              this.data_items_original = this.existing_voucher.request_data
              var tto = 0;
              var datas = []
              this.data_items_original.forEach(function (item) {
                tto += parseFloat(item.amount)
                datas.push({
                  id: item.id,
                  date: item.date_of_implementation,
                  request_id: item.request_id,
                  created_by: item.request.created_by.name,
                  sector: item.request.sector,
                  particulars: item.request_particulars.particulars,
                  description: item.description,
                  amount: item.amount
                })
              });
              this.total_amount = this.formatPrice(tto)
              this.data_items = datas
            }
          })
          .catch(error => {
            console.log(error)
          })
        this.list_of_available_for_cash_voucher()
          .then(response => {
            this.request_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_request(value) {
        var index = this.request_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          var datas = []
          var tto = 0;
          var sector = this.request_items[index].sector
          var created_by = this.request_items[index].created_by.name
          this.request_items[index].request_data.forEach(function (item) {
            tto += parseFloat(item.amount)
            datas.push({
              id: item.id,
              date: item.date_of_implementation,
              request_id: item.request_id,
              created_by: created_by,
              sector: sector,
              particulars: item.request_particulars.particulars,
              description: item.description,
              amount: item.amount
            })
          });
          this.data_items_original.forEach(function (item) {
            tto += parseFloat(item.amount)
            datas.push({
              id: item.id,
              date: item.date_of_implementation,
              request_id: item.request_id,
              created_by: created_by,
              sector: sector,
              particulars: item.request_particulars.particulars,
              description: item.description,
              amount: item.amount
            })
          });
          this.total_amount = this.formatPrice(tto)
          this.data_items = datas
        }
      },
      delete_voucher() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.existing_voucher.id);
          this.delete_cash_voucher(data)
            .then(() => {
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      save_voucher() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true;
          if (this.name_of_sponsor != null) {
            if (this.name_of_sponsor.id === undefined) {
              this.alert = true
              this.alert_message = 'Please Search Sponsor First'
              this.saving = false
              proceed = false
            }
          }
          if (proceed) {
            const data = new FormData()
            data.append('bank', this.bank_id.toUpperCase());
            data.append('date', this.cheque_date);
            data.append('type_of_sector', this.type_of_sector);
            data.append('payee', this.payee.toUpperCase());
            data.append('allocation_of_fund', this.allocation_of_fund.toUpperCase());
            data.append('created_id', this.user_id);
            data.append('sponsor_member_id', this.name_of_sponsor.id);

            this.create_cash_voucher(data)
              .then(response => {
                var color = 'error'
                if (response.status === 200) {
                  var color = 'success'
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
                this.saving_data = false
                Object.assign(this.$data, initialState())
                this.initialize_data()
              })
              .catch(error => {
                console.log(error)
              })
          }

        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      save_request() {
        this.saving_data_request = true
        this.alert_request = false
        if (this.$refs.form2.validate()) {
          this.save_request_data_cash_voucher({
            request_id: this.request_id,
            cv_id: this.existing_voucher.id
          })
            .then(() => {
              this.saving_data_request = false
              this.change_snackbar({
                show: true,
                color: 'success',
                text: 'SUCCESSFULLY ADDED REQUEST',
              })
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert_request = true
          this.alert_message_request = 'Please Fill up the field/s'
          this.saving_data_request = false
        }
      },
      approve_voucher() {
        this.approved_cash_voucher({
          id: this.existing_voucher.id
        })
          .then(() => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: 'SUCCESSFULL APPROVED VOUCHER',
            })
            Object.assign(this.$data, initialState())
            this.initialize_data()
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
